var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"align-start "},[_c('span',[_vm._v("Challenges ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded font-weight-regular",attrs:{"headers":_vm.headers,"items":_vm.challenges,"item-key":"id","loading":_vm.loading,"search":_vm.search,"disable-sort":"","server-items-length":_vm.pagination.total,"page-count":_vm.pagination.last_page,"options":_vm.options,"page":_vm.pagination.current_page,"items-per-page":_vm.pagination.per_page,"footer-props":{
      'items-per-page-options': [20, 50, 100, 200],
    }},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){return _vm.$set(_vm.pagination, "current_page", $event)}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.color(item.status),"dark":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.target_amount",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.target_amount))+" ")])]}},{key:"item.wallet.balance",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.wallet.balance))+" ")])]}},{key:"item.progress",fn:function(ref){
    var item = ref.item;
return [_c('v-progress-circular',{attrs:{"value":item.progress,"color":"primary","size":50}},[_vm._v(" "+_vm._s(item.progress)+"% ")])]}},{key:"item.details",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1 text-lowercase",attrs:{"to":{ name: 'challenge-details', params: { id: item.id } },"color":"primary","small":"","plain":""}},[_vm._v(" view challenge --> ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }