<template>
  <v-card>
    <v-card-title class="align-start ">
      <span>Challenges </span>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="challenges"
      item-key="id"
      class="table-rounded font-weight-regular"
      :loading="loading"
      :search="search"
      disable-sort
      :server-items-length="pagination.total"
      :page-count="pagination.last_page"
      :options.sync="options"
      :page.sync="pagination.current_page"
      :items-per-page="pagination.per_page"
      :footer-props="{
        'items-per-page-options': [20, 50, 100, 200],
      }"
    >
      <template #[`item.status`]="{item}">
        <v-chip :color="color(item.status)" dark>
          {{ item.status }}
        </v-chip>
      </template>
      <template #[`item.target_amount`]="{item}">
        <span class="font-weight-black">
          {{ Intl.NumberFormat().format(item.target_amount) }}
        </span>
      </template>
      <template #[`item.wallet.balance`]="{item}">
        <span>
          {{ Intl.NumberFormat().format(item.wallet.balance) }}
        </span>
      </template>
      <template #[`item.progress`]="{item}">
        <v-progress-circular :value="item.progress" color="primary" :size="50">
          {{ item.progress }}%
        </v-progress-circular>
      </template>
      <template #[`item.details`]="{item}">
        <v-btn
          :to="{ name: 'challenge-details', params: { id: item.id } }"
          color="primary"
          small
          class="mx-1 text-lowercase"
          plain
        >
          view challenge -->
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  setup() {
    return {
      headers: [
        { text: 'NAME', value: 'name' },
        { text: 'TARGET', value: 'target_amount' },
        { text: 'TOTAL SAVED', value: 'wallet.balance' },
        { text: 'PROGRESS', value: 'progress' },
        { text: 'STATUS', value: 'status' },
        { text: 'WITHDRAW DATE', value: 'end_date' },
        { text: 'DAYS LEFT', value: 'days_left' },
        { text: 'More', value: 'details' },
      ],
      color(status) {
        if (status === 'active') {
          return 'secondary'
        }
        if (status === 'gaveup') {
          return 'error'
        }

        return 'success'
      },
    }
  },
  data() {
    return {
      challenges: [],
      loading: true,
      search: '',
      errors: {},
      options: {},
      pagination: {},
    }
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
    },
    deep: true,
  },
  created() {
    this.fetchApiData()
  },
  methods: {
    fetchApiData() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      this.$http
        .get(`/challenges?page=${page}&per_page=${itemsPerPage}`)
        .then(res => {
          this.challenges = res.data.data
          this.pagination = res.data.meta
        })
        .finally(() => {
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>
